import { useEffect, useMemo, useState } from "react";
import { convertSecondsToHMS, createNoticeMarker, formatLocaleDateTime, hasEditPermissionForOrg, requestedTimeFormat } from "../../util";
import { AddButton, Centered, EditButton, ErrorMsg, Loader, Button, SurfaceLevelButton } from "../Sugar";
import Breadcrumbs, { BreadcrumbLink } from "../Breadcrumbs";
import SectionBox from '../SectionBox';
import LeafletMap, { IMarker } from '../Map';
import styles from './styles.module.css'
import API, { ApiEquipmentWithSeries, ApiFleet, ApiStation } from "../../api";
import { Link, useNavigate, useParams } from "react-router-dom";
import Table from "../Table";
import Title from "../Title";
import ArrowButton from "../Button";
import { ChartData, IAlert, INoticeMarker, IPump, PlotData, Serie, Status } from "../../types";
import Plot, { plotColors } from "../Plot"
import { useAlerts, useAuth } from "../../hooks";
import RecentAlerts from "../RecentAlerts";
import { useTranslation } from "react-i18next";
import { mostSevereAlertStatus, stateToStatus } from "../Alerts";

interface OverviewTableProps {
  pumps: Partial<IPump>[],
  deviceInstallations: DeviceInstallation[],
  surfaceLevel: ApiEquipmentWithSeries | null,
  fleetInfo: any
}

interface DeviceInstallation {
  installTime: Date,
  serial: string,
  typeCode: string,
  uninstallTime: Date | null
}

interface StationChartProps {
  series: Serie[],
  timeRange: string[],
  setTimeRange: (timeRange: string[]) => void,
  noticeMarker: INoticeMarker | null,
  removeNoticeMarker: () => void
}

interface IntermediateChartProps {
  series: Serie[],
  timeRange: string[],
  setTimeRange: (timeRange: string[]) => void,
  type: string
  title: string
  unit: string
}

interface IntermediateTableProps {
  series: Serie[],
  timeRange: string[],
}

interface IntermediateValuesProps {
  series: Serie[],
  timeRange: string[],
  setTimeRange: (timeRange: string[]) => void,
}

interface IntermediateTableItem{
  name: string,
  range?: number,
  total?: number,
  cycles?: number
}

export const formatStationPath = (fleets: {name: string, publicId: string}[]): BreadcrumbLink[] => {
  // Given a path of fleets, format them for breadcrumbs use so that the last
  // one is considered a station and the others fleets.
  return fleets.map((fleet, i) => {
    return {
      to: i === fleets.length - 1 ? `/station/${fleet.publicId}` : `/fleet/${fleet.publicId}`,
      title: fleet.name,
    }
  });
};

const OverviewTable = ({ pumps, deviceInstallations, surfaceLevel, fleetInfo }: OverviewTableProps): JSX.Element => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { alerts, loadingAlerts } = useAlerts();
  const navigate = useNavigate();

  const editPermission = hasEditPermissionForOrg(fleetInfo.fleet.organizationPublicId, user);
  const stationId: string = fleetInfo.fleet.publicId;

  const tableColumns: [keyof IPump, string][] = [
    ['name', ''],
    ['modelName', t('equipment_model_name')],
    ['nominalCurrent', t('rated_current')],
    ['nominalPower', t('rated_power')],
    ['motorFaultAlert', t('motor_fault_alert')]
  ];
  const formatters: Record<string, any> = {
    'nominalCurrent': (val: number) => `${val.toFixed(1)} A`,
    'nominalPower': (val: number) => `${val.toFixed(1)} kW`,
  }
  const pumpRows: JSX.Element[] = [];
  if (pumps.length > 0 && !loadingAlerts && alerts) {
    for (const [key, label] of tableColumns) {
      const pumpColumns: JSX.Element [] = [];
      for (const pump of pumps) {
        const formatter = formatters[key];
        const value = pump[key] || typeof pump[key] === 'boolean' ? (formatter ? formatter(pump[key]) : pump[key]) : '-';
        let element: string | JSX.Element = '';
        if (key === 'name') {
          const maybeAlert = alerts.find(alert => alert.forEquipmentPublicId === pump.publicId);
          const status = maybeAlert ? stateToStatus(maybeAlert.lastState): 'OK';
          element = <ArrowButton text={value} bgColor={'light'} status={status} onClick={() => navigate(`/pump/${pump.publicId}`)} />;
        } else if (key === 'motorFaultAlert') {
          if (value) {
            element = t('alerts_enabled');
          } else {
            if (editPermission) {
              element = <Button text={t('add_alert')} onClick={() => navigate(`/station/${stationId}/new-motor-alert/${pump.publicId}`)} />;
            } else {
              element = t('alerts_disabled');
            }
          }
        } else {
          element = value;
        }
        pumpColumns.push(
          <td key={pump.publicId}>{ element }</td>
        );
      }
      pumpRows.push(
        <tr key={ key }>
          <td>{ label }</td>
          { pumpColumns }
        </tr>
      );
    }
  }
  if (surfaceLevel && surfaceLevel.series.find((s) => (s.quantity === 'overflow_alert'))) {
    pumpRows.push(
      <tr key={'overflowAlert'}>
        <td style={{borderRight: '1px solid white'}}>{t('overflow_alert')}</td>
        <td colSpan={Math.max(1, pumps.length)}>
          { Boolean(surfaceLevel.series.find((s) => s.hasAlertRules)) ? t('alerts_enabled') : (editPermission ? <Button text={t('add_alert')} onClick={() => navigate(`/station/${stationId}/new-overflow-alert `)} /> : t('alerts_disabled'))}
        </td>
      </tr>
    )
  }

  if (deviceInstallations.length > 0) {
    pumpRows.push(
      <tr key={'VT3 Deployment'}>
        <td style={{borderRight: '1px solid white'}}>{`VT3 ${t('deployment')}`}</td>
        <td colSpan={Math.max(1, pumps.length)}>
          <div className={styles.vtDeployment}>
            { deviceInstallations.map((item: DeviceInstallation) => {
              const installTime = formatLocaleDateTime(item.installTime)
              if (!item.uninstallTime) {
                return (
                  <div key={item.serial}>
                    <p>{`${t('serial')}: `}<Link to={`/devices/${item.serial}`}>{item.serial}</Link></p>
                    <p>{`${t('installed')}: ${installTime}`}</p>
                  </div>
                )
              }
              return null
            })}
          </div>
        </td>
      </tr>
    )
  }

  return <Table rows={pumpRows} noFirstBorder />
}

const StationChart = ({ series, timeRange, setTimeRange, noticeMarker, removeNoticeMarker }: StationChartProps): JSX.Element => {
  const { t } = useTranslation();
  const [isInitialLoading, setIsInitialLoading] = useState<boolean>(true);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [chartData, setChartData] = useState<any>(null);
  const [refreshIteration, setRefreshIteration] = useState<number>(0); // TODO: hack :)
  const [maxPhaseCurrent, setMaxPhaseCurrent] = useState<number>(0);

  //Should automatic refreshing be enabled for chart?
  // Yes if the range is relative (e.g. last x// hours).
  const isAutoRefreshOn = timeRange[0].includes('now') || timeRange[1].includes('now');

  useEffect(() => {
    let isMounted = true;
    const pendingRequests = new Map();
    const tmpChartData: { phaseCurrents: ChartData[], surfaceLevel: ChartData | null } = {phaseCurrents: [], surfaceLevel: null};
    let maybeUpdateTimer: ReturnType<typeof setTimeout> | null = null;

    const fetchSeries = (serie: Serie) => {
      const aborter = new AbortController();
      // Save the aborter.
      pendingRequests.set(serie.publicId, aborter);
      const params = new URLSearchParams({
        from: requestedTimeFormat(timeRange[0]),
        to: requestedTimeFormat(timeRange[1])
      }).toString()

      API.getSeries(serie.publicId, params, aborter.signal).then((data: PlotData) => {
        pendingRequests.delete(serie.publicId);
        if (serie.type === "phase_current") {
          tmpChartData.phaseCurrents.push({...serie, data});
          const newMax = Math.max(...data.values);
          if (isMounted && newMax > maxPhaseCurrent) {
            setMaxPhaseCurrent(Math.max(...data.values));
          }
        } else {
          tmpChartData.surfaceLevel = {...serie, data};
        }
        if (isMounted && pendingRequests.size === 0) {
          // Make sure the series are sorted: Pump 1, Pump 2, ...
          tmpChartData.phaseCurrents = tmpChartData.phaseCurrents.sort(
            (a: ChartData, b: ChartData) => a.name.localeCompare(b.name)
          );
          setIsInitialLoading(false);
          setChartData(tmpChartData);

          if (isAutoRefreshOn) {
            maybeUpdateTimer = setTimeout(() => {
              setRefreshIteration(refreshIteration + 1);
            }, 5000);
          }
        }
      }).catch(() => {
        setIsInitialLoading(false);
        setErrorMsg(t('error_history_data'));
        abortAllRequests();
      });
    }

    const abortAllRequests = () => {
      for (const publicId in pendingRequests) {
        pendingRequests.get(publicId).abort();
      }
      pendingRequests.clear();
    };
    if (series.length !== 0) {
      for (const serie of series) {
        fetchSeries(serie);
      }
    } else {
      setErrorMsg(t('no_history_data_available'));
      setIsInitialLoading(false);
    }

    // cleanup
    return () => {
      isMounted = false;
      abortAllRequests();
      if (maybeUpdateTimer !== null) {
        clearTimeout(maybeUpdateTimer);
      }
    };
  }, [series, timeRange, refreshIteration, isAutoRefreshOn, maxPhaseCurrent, t]);

  if (isInitialLoading) {
    return (
      <Loader text={t('loading_history_data')} />
    );
  }

  if (chartData) {
    const plotlyDataArray = chartData.phaseCurrents.map((serie: ChartData, index: number) => {
      return {
        type: 'scatter',
        x: serie.data.times.map((t) => (new Date(t))),
        y: serie.data.values,
        yhoverformat: '.2f',
        mode: 'lines',
        line: {
          shape: 'hv', // stairstep
          color: plotColors[index]
        },
        name: serie.name,
        connectgaps: false,
      };
    });
    if (chartData.surfaceLevel) {
      plotlyDataArray.push({
        type: 'scatter',
        x: chartData.surfaceLevel.data.times.map((t: string) => new Date(t)),
        y: chartData.surfaceLevel.data.values,
        yhoverformat: '.2f',
        mode: 'lines',
        name: t('surface_level'),
        yaxis: 'y2',
        connectgaps: false,
        line: {
          color: '#0093E5',
        }
      })
    }

    // Pick the phase current axis y maximum. Get a round value with some headroom.
    const phaseCurrentRangeMax = Math.max(2.0, 1.2 * maxPhaseCurrent);

    return (
      <Plot
        data={plotlyDataArray}
        timeRange={timeRange}
        setTimeRange={setTimeRange}
        removeNoticeMarker={removeNoticeMarker}
        noticeMarker={noticeMarker}
        showTimerangePicker
        layout={{
          legend: {
            y: -0.2
          },
          xaxis: {
            type: 'date',
            showgrid: false,
            color: 'black',
          },
          yaxis: {
            title: `${t('phase_current')} (A)`,
            range: [0.0, phaseCurrentRangeMax],
            color: 'black',
            gridcolor: '#BDBCBC',
          },
          yaxis2: {
            title: `${t('surface_level')} (m)`,
            rangemode: 'tozero',
            color: 'black',
            overlaying: 'y',
            side: 'right',
            showgrid: false,
          },
        }}
      />
    )
  }
  // Error loading
  return (
    <Centered>
      <ErrorMsg message={errorMsg} />
    </Centered>
  );
}

const IntermediateChart = ({ series, type, title, timeRange, setTimeRange, unit }: IntermediateChartProps) => {
  const { t } = useTranslation();
  const [isInitialLoading, setIsInitialLoading] = useState<boolean>(true);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [chartData, setChartData] = useState<any>(null);
  const [refreshIteration, setRefreshIteration] = useState<number>(0); // TODO: hack :)
  const [yMax, setYMax] = useState<number>(0);

  //Should automatic refreshing be enabled for chart?
  // Yes if the range is relative (e.g. last x// hours).
  const isAutoRefreshOn = timeRange[0].includes('now') || timeRange[1].includes('now');

  useEffect(() => {
    let isMounted = true;
    const aborter = new AbortController();
    const pendingRequests = new Map();
    let tmpChartData: ChartData[] = [];
    let maybeUpdateTimer: ReturnType<typeof setTimeout> | null = null;
    let yAxisMax: number = 0;
    
    const fetchSeries = (serie: Serie) => {
      // Save the aborter.
      pendingRequests.set(serie.publicId, aborter);
      const params = new URLSearchParams({
        from: requestedTimeFormat(timeRange[0]),
        to: requestedTimeFormat(timeRange[1]),
        iv: type
      }).toString()

      API.getIntermediateSeries(serie.publicId, params, aborter.signal).then((data: PlotData) => {
        pendingRequests.delete(serie.publicId);
        tmpChartData.push({...serie, data});
        const newMax = Math.max(...data.values);
        if (newMax > yAxisMax) {
          yAxisMax = Math.max(...data.values);
        }
        if (isMounted && pendingRequests.size === 0) {
          tmpChartData = tmpChartData.sort((a, b) => a.name.localeCompare(b.name));
          setChartData(tmpChartData);
          setYMax(yAxisMax);

          if (isAutoRefreshOn) {
            maybeUpdateTimer = setTimeout(() => {
              setRefreshIteration(refreshIteration + 1);
            }, 10000);
          }
          setIsInitialLoading(false);
        }
      }).catch(() => {
        if (isMounted) {
          setErrorMsg(t('error_data'));
          setIsInitialLoading(false);
          abortAllRequests();
        }
      })
    }

    const abortAllRequests = () => {
      for (const publicId in pendingRequests) {
        pendingRequests.get(publicId).abort();
      }
      pendingRequests.clear();
      aborter.abort();
    };
    if (series.length !== 0) {
      for (const serie of series) {
        fetchSeries(serie);
      }
    }
    
    // cleanup
    return () => {
      isMounted = false;
      abortAllRequests();
      if (maybeUpdateTimer !== null) {
        clearTimeout(maybeUpdateTimer);
      }
    };
  }, [series, timeRange, refreshIteration, isAutoRefreshOn, type, t]);

  if (isInitialLoading) {
    return (
      <Loader />
    );
  }

  if (chartData) {
    const plotlyDataArray = chartData.map((serie: ChartData, index: number) => {
      const averageValueStr: string = (serie.data.avg === undefined || serie.data.avg === null)
        ? '–' : `${serie.data.avg.toFixed(2)} ${unit}`;

      return {
        type: 'scatter',
        mode: 'lines+markers',
        x: serie.data.times.map(t => new Date(t)),
        y: serie.data.values,
        yhoverformat: '.2f',
        line: {
          shape: 'linear',
          color: plotColors[index]
        },
        name: `${serie.name} (${t('avg')}: ${averageValueStr})`,
        connectgaps: false,
      };
    });

    // Pick the phase current axis y maximum. Get a round value with some headroom.
    const yRangeMax = Math.max(2.0, 1.2 * yMax);

    return (
      <Plot
        data={plotlyDataArray}
        timeRange={timeRange}
        setTimeRange={setTimeRange}
        yAnnotation={unit}
        layout={{
          legend: {
            y: -0.2,
          },
          xaxis: {
            type: 'date',
            showgrid: false,
            color: 'black',
          },
          yaxis: {
            range: [0.0, yRangeMax],
            color: 'black',
            gridcolor: '#BDBCBC',
          },
          title: {
            text: title,
            yanchor: 'top',
            font: {
              color: 'black',
            },
          },
        }}
      />
    )
  }
  // Error loading
  return (
    <Centered>
      <ErrorMsg message={errorMsg} />
    </Centered>
  );
}

const IntermediateTable = ({ series, timeRange }: IntermediateTableProps) => {
  const { t } = useTranslation();
  const [tableData, setTableData] = useState<IntermediateTableItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');

  useEffect(() => {
    let isMounted = true;
    const aborter = new AbortController();
    const getParams = (iv: string) => {
      return new URLSearchParams({
        from: requestedTimeFormat(timeRange[0]),
        to: requestedTimeFormat(timeRange[1]),
        iv: iv
      }).toString()
    }

    setIsLoading(true);
    const cumulativeRequests: Promise<IntermediateTableItem>[] = series.map((serie: Serie) => (
      API.getIntermediateSeries(serie.publicId, getParams('cumulative_on_time'), aborter.signal).then((data: PlotData) => {
        const diff: number = data.values[data.values.length-1] - data.values[0];
        const latest: number | undefined = data.values[data.values.length-1];
        let total: number | undefined = 0;
        if (latest) {
          total = data.values[data.values.length-1];
        }
        return { name: serie.name, range: !diff || diff === 0 ? 0 : diff, total: total }
      })
    ))

    const cyclesRequests: Promise<IntermediateTableItem>[] = series.map((serie: Serie) => (
      API.getIntermediateSeries(serie.publicId, getParams('num_cycles'), aborter.signal).then((data: PlotData) => {
        const numCycles = data.values.reduce((acc: number, a: number | null) => acc + (a || 0), 0);
        return { name: serie.name, cycles: numCycles };
      })
    ))

    Promise.all(cumulativeRequests.concat(cyclesRequests)).then(values => {
      const newValues = values.map(value => {
        if ('total' in value && value.total === 0) {
          return { ...value, total: tableData.find(item => item.name === value.name)?.total };
        }
        return value
      })
      setTableData(newValues);
      setIsLoading(false);
    }).catch(() => {
      if (isMounted) {
        setErrorMsg(t('error_data'));
        setIsLoading(false);
      }
    })

    return () => {
      aborter.abort();
      isMounted = false;
    }
  }, [series, timeRange, t]);

  const tableHeader = [''];
  const columns: [keyof IntermediateTableItem, string][] = [
    ['total', t('total_running_time')],
    ['range', t('running_time_within_time_range')],
    ['cycles', t('cycles_within_time_range')],
  ];
  const rows: JSX.Element[] = [];
  for (const [key, label] of columns) {
    const dataColumns: JSX.Element[] = [];
    tableData.forEach((item, i) => {
      if (!tableHeader.includes(item.name)) {
        tableHeader.push(item.name);
      }
      if (item[key] || item[key] === 0) {
        dataColumns.push(<td key={`${label}-${i}`}>{item[key] === 0 ? 0 : (key === 'cycles' ? item[key] : convertSecondsToHMS(item[key] as number))}</td>);
      }
    })
    rows.push(
      <tr key={key}>
        <td>{label}</td>
        { dataColumns }
      </tr>
    );
  }

  if (errorMsg) {
    return (
      <Centered>
      <ErrorMsg message={errorMsg} />
    </Centered>
    )
  }

  return <Table rows={rows} head={tableHeader} loading={isLoading} />
}

const IntermediateValues = ({ series, timeRange, setTimeRange }: IntermediateValuesProps) => {
  const { t } = useTranslation();

  const chartConfig = [
    {
      type: 'cycle_on_time',
      title: t('pumping_cycle_length'),
      unit: 'min'
    },
    {
      type: 'cycle_on_median',
      title: t('operating_current'),
      unit: 'A'
    },
    {
      type: 'cycle_off_time',
      title: t('pumping_cycles_interval'),
      unit: 'h'
    },
    {
      type: 'duty_cycle',
      title: t('percentage_of_time_on'),
      unit: '%'
    },
  ]

  return (
    <>
      <h1>{t('intermediate_values')}</h1>
      { series.length > 0 ?
        <>
          <div className={styles.intermediateValuesLayout}>
            <SectionBox layoutArea={'table'}>
              <IntermediateTable series={series} timeRange={timeRange} />
            </SectionBox>

            { chartConfig.map(item => {
              return (
                <SectionBox key={item.title} layoutArea={item.type}>
                  <IntermediateChart
                    series={series}
                    timeRange={timeRange}
                    setTimeRange={setTimeRange}
                    type={item.type}
                    title={item.title}
                    unit={item.unit}
                  />
                </SectionBox>
              )
            }) }
          </div>
        </>
      :
        <SectionBox>
          <Centered>
            <ErrorMsg message={t('no_data_available')} />
          </Centered>
        </SectionBox>
      }
    </>
  );
}

const Address = ({fleet}: {fleet: ApiFleet}): JSX.Element => {
  const { t } = useTranslation();

  const columns: [keyof ApiFleet, string][] = [
    ['address', t('no_street_address')],
    ['postalCode', t('no_postal_code')],
    ['locality', t('no_locality')]
  ];
  const rows: JSX.Element[] = [];
  for (const [key, label] of columns) {
    const value = fleet[key] ? <p key={key}>{fleet[key]}</p> : <p key={key} style={{fontStyle: 'italic'}}>{label}</p>;
    rows.push(value);
  }
  return (
    <div className={styles.address}>
      {rows}
    </div>
  )
};

const Station = (): JSX.Element => {
  const { t } = useTranslation();
  const { alerts, loadingAlerts } = useAlerts();
  const { user } = useAuth();
  const params = useParams();
  const navigate = useNavigate();
  const [title, setTitle] = useState<string | null>(null);
  const [stationRespData, setStationRespData] = useState<ApiStation | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [timeRange, setTimeRange] = useState<string[]>(['now-24h', 'now']);
  const [noticeMarker, setNoticeMarker] = useState<INoticeMarker | null>(null);
  const [alertHistory, setAlertHistory] = useState<IAlert[] | null>(null);
  const stationId = params.publicId;

  useEffect(() => {
    let isMounted = true;
    if (stationId) {
      const abortRequest = new AbortController();
      API.getStationById(stationId, abortRequest.signal).then(data => {
        if (data.fleet.children.length > 0) {
          // This is not a leaf fleet.
          navigate('/fleet');
        }
        setStationRespData(data);
        const fleetNames = data.partOfFleets.map((item) => `${item.name} - `).join('');
        setTitle(fleetNames + data.fleet.name);
      }).catch(() => {
        navigate('/fleet');
      }).finally(() => {
        if (isMounted) {
          setLoading(false);
        }
      })
      return () => {
        isMounted = false;
        abortRequest.abort();
      };
    }
  }, [stationId, navigate]);

  // Load alert history data for station.
  useEffect(() => {
    let isMounted = true;
    if (stationId) {
      const params = new URLSearchParams({fleet: stationId, page: '0', orderBy: '-severity,-startTime'});
      API.getAlerts(params.toString()).then(data => {
        if (isMounted) {
          const alerts: IAlert[] = data.alerts;
          setAlertHistory(alerts.slice(0, 5)); // Pick 5.
        }
      })
      return () => {
        isMounted = false;
      };
    }
  }, [stationId]);

  const addNoticeMarker = (notice: IAlert) => {
    const { timerangeStart, timerangeEnd, noticeTitle, noticeStart } = createNoticeMarker(notice);
    setTimeRange([timerangeStart, timerangeEnd]);
    setNoticeMarker({ title: noticeTitle, startTime: noticeStart });
  }

  const removeNoticeMarker = () => {
    setNoticeMarker(null);
  }

  const [alertStatus, mapMarker]: [Status, IMarker[]] = useMemo(() => {
    let status: Status = 'OK';
    const mapMarker: IMarker[] = [];
    if (alerts && !loadingAlerts && stationRespData) {
      const stationAlerts = alerts.filter(alert => alert.forFleetPublicId === stationId);
      status = mostSevereAlertStatus(stationAlerts);
      if (stationRespData.fleet.coordinates) {
        const status = alerts.findIndex(alert => alert.forFleetPublicId === stationRespData.fleet.publicId) > -1 ? 'DANGER' : 'OK';
        const partOfFleets = stationRespData.partOfFleets.map((item: any) => item.name)
        partOfFleets.push(stationRespData.fleet.name)
        mapMarker.push({
          coordinates: {lat: stationRespData.fleet.coordinates[0], lng: stationRespData.fleet.coordinates[1]},
          partOfFleets: partOfFleets,
          status: status,
          publicId: stationRespData.fleet.publicId,
          navigation: false
        })
      }
    }
    return [status, mapMarker];
  }, [stationId, alerts, loadingAlerts, stationRespData]);

  const [pumps, chartedSeries, phaseCurrentSeries, surfaceLevel] = useMemo(() => {
    const pumps: Partial<IPump>[] = [];
    const chartedSeries: Serie[] = [];
    const phaseCurrentSeries: Serie[] = [];
    // surfaceLevel seems to mean "equipment that has a surface level" == well/tank
    let surfaceLevel: ApiEquipmentWithSeries | null = null;
    if (stationRespData !== null) {
      for (const eq of stationRespData.equipment) {
        if ('pump' in eq || 'motor' in eq) {
          pumps.push({
            publicId: eq.publicId,
            name: eq.name,
            modelName: (eq.pump && eq.pump.modelName) || (eq.motor && eq.motor.modelName) || undefined,
            nominalCurrent: (eq.motor && eq.motor.nominalCurrentAmperes) || undefined,
            nominalPower: (eq.motor && eq.motor.nominalPower) || (eq.pump && eq.pump.nominalPower) || undefined,
            motorFaultAlert: Boolean(eq.series.find((s) => s.hasAlertRules)),
          });
        } else {
          surfaceLevel = eq;
        }
        for (const s of eq.series) {
          if (s.quantity === 'phase_current') {
            phaseCurrentSeries.push({
              name: eq.name,
              publicId: s.id,
            });
          }
          if (s.quantity === 'phase_current' || s.quantity === 'surface_level') {
            chartedSeries.push({
              name: eq.name,
              type: s.quantity,
              publicId: s.id,
            });
          }
        }
      }
    }
    return [pumps, chartedSeries, phaseCurrentSeries, surfaceLevel];
  }, [stationRespData])

  if (loading || !stationRespData) {
    return (
      <Loader text={t('loading_station')} />
    );
  }

  const breadcrumbs = formatStationPath(stationRespData ? [...stationRespData.partOfFleets, stationRespData.fleet]: []);

  return (
    <>
      { title !== null && <Title title={title} /> }
      <div style={{display: 'flex'}}>
        <Breadcrumbs leftArrowTo='/fleet' links={breadcrumbs} status={alertStatus} />
        { hasEditPermissionForOrg(stationRespData.fleet.organizationPublicId, user) &&
          <div style={{marginLeft: 'auto', padding: '0 0 0 10px'}}>
            <EditButton
              onClick={() => navigate(`/fleet/${stationId}/edit`, {state: { from: 'station' }})}
              data-tooltip-id='main-tooltip'
              data-tooltip-content={t('edit_station')}
              data-tooltip-place='left'
            />
          </div>
        }
      </div>
      <div className={styles.stationPageLayout}>
        <div className={styles.firstSection}>
          <SectionBox title={t('station_overview')} status={alertStatus}>
            <div style={{display: 'flex', flexDirection: 'column'}}>
            <OverviewTable pumps={pumps} deviceInstallations={
              stationRespData.deviceInstallations.map((di) => ({
                serial: di.serial,
                typeCode: di.typeCode,
                installTime: new Date(di.installTime),
                uninstallTime: di.uninstallTime !== null ? new Date(di.uninstallTime) : null,
              }))
            } surfaceLevel={surfaceLevel} fleetInfo={stationRespData} />
              { hasEditPermissionForOrg(stationRespData.fleet.organizationPublicId, user) && 
                <div style={{margin: '10px 0 0 auto', display: 'flex', flexDirection: 'row'}}>
                  { !surfaceLevel && 
                    <div style={{marginRight: pumps.length < 2 ? 20: 0}}>
                      <SurfaceLevelButton onClick={() => navigate(`/station/${stationId}/new-surface-level`)} />
                    </div>
                  }
                  { pumps.length < 2 && 
                    <AddButton onClick={() => navigate(`/station/${stationId}/new-pump`)} />
                  }
                </div>
              }
            </div>
          </SectionBox>

          { alertHistory && (
            <SectionBox title={`${t('alerts')}`} noOverflow marginTop>
              { alertHistory.length === 0 ? (
                <p>{t('no_alerts')}</p>
              ) : (
                <>
                  <RecentAlerts
                    alertHistory={alertHistory}
                    showAlerting={alertStatus !== 'OK'}
                    onJumpToAlert={addNoticeMarker}
                  />
                  <div className={styles.allAlerts}>
                    <Link to={`/alerts?fleet=${stationId}`}>{ t('show_all_alerts') }</Link>
                  </div>
                </>
              ) }
            </SectionBox>
          )}

          <SectionBox title={t('location')} marginTop>
            { stationRespData.fleet.coordinates ?
              <LeafletMap
                markers={mapMarker}
              />
            :
              <Centered>
                <ErrorMsg message={t('no_coordinates')} />
              </Centered>
            }
            <Address fleet={stationRespData.fleet} />
          </SectionBox>
        </div>
        <div className={styles.secondSection}>
          <SectionBox title={t('history')} noOverflow>
            <StationChart
              series={chartedSeries}
              timeRange={timeRange}
              setTimeRange={setTimeRange}
              noticeMarker={noticeMarker}
              removeNoticeMarker={removeNoticeMarker}
            />
          </SectionBox>
        </div>
      </div>

      <IntermediateValues
        series={phaseCurrentSeries}
        timeRange={timeRange}
        setTimeRange={setTimeRange}
      />
    </>
  );
}

export default Station;
