import styles from './styles.module.css';
import { formatLocaleDateTime, timeSince } from "../../util";
import { useTranslation } from "react-i18next";
import Table from "../Table";
import { InfoIcon } from '../../icons';
import { DeviceData, DeviceInput } from "../../types";
import i18n from '../../i18n';

interface InputsTableProps {
  data?: DeviceData | null,
  filter?: 'analog' | 'digital'
}

export const formatInputValue = (deviceInput: DeviceInput, type: string) => {
  const { input, value } = deviceInput;
  let result: string | null = null;
  if (value !== null) {
    if (input.startsWith('CT')) {
      result = `${(value as number).toFixed(2)} A`;
    } else if (input.startsWith('CL')) {
      result = type.startsWith('VT04') ? `${(value as number * 1000).toFixed(2)} A` : `${(value as number).toFixed(2)} mA`;
    } else {
      result = value ? i18n.t('voltage_on') : i18n.t('voltage_off');
    }
  }
  return result ? result : i18n.t('no_info');
}

const DeviceInputsTable = ({ data, filter }: InputsTableProps) => {
  const { t } = useTranslation();

  const inputs = (data && data.inputs) || [];
  const type = (data && data.type) || '';

  const formatRow = (input: DeviceInput) => {
    return (
      <div className={styles.deviceInputsTableRow}>
        <div style={{marginRight: 5}}>{formatInputValue(input, type)}</div>
        { input.time &&
          <>
            <div
              data-tooltip-id='main-tooltip'
              data-tooltip-content={`${t('updated_at')} ${formatLocaleDateTime(input.time)}`}
              data-tooltip-place='top'
            >
              <InfoIcon />
            </div>
            <div className={styles.timeSince}>{timeSince(new Date(input.time))}</div>
          </>
        }
      </div>
    )
  }
  
  const analogRows: JSX.Element[] = [];
  const digitalRows: JSX.Element[] = [];
  for (const i of inputs) {
    const { input } = i;
    if (input.startsWith('DI')) {
      digitalRows.push(
        <tr key={ input }>
          <td style={{width: 150}}>{ i.input }</td>
          <td>{ formatRow(i) }</td>
        </tr>
      );
    } else {
      analogRows.push(
        <tr key={ input }>
          <td style={{width: 150}}>{ input }</td>
          <td>{ formatRow(i) }</td>
        </tr>
      );
    }
  }
  
  return (
    <div className={styles.deviceInputsTable}>
      { filter !== 'digital' &&
        <>
          <h3>{ t('status_of_analog_inputs') }</h3>
          <Table rows={analogRows} />
        </>
      }
      { filter !== 'analog' &&
        <>
          <h3>{ t('status_of_digital_inputs') }</h3>
          <Table rows={digitalRows} />
        </>
      }
    </div>
  )
}

export default DeviceInputsTable